<template>
  <v-container>
    <v-row  color="white" class="my_row">
      <v-col style="margin: 0 auto;" sm="12" md="6" cols="12" class="screen_small pr-0">
        <v-card
            color="white"
            class="pa-10 rounded-0 no_border wrapper_form"
        >
          <h1 style="padding:15px;text-align: center;" class="mb-0 pb-0 line_40">ប្រព័ន្ធលោកអ្នកស្ថិតក្នុងការថែទាំ</h1>
          <h1 style="padding:15px;font-size: 18px;text-align: center;" class="mb-6 pb-0 pt-0 line_40">Your system under maintenance</h1>
          <v-row>
            <v-col sm="12" md="12" cols="12" style="padding: 0;max-width: 90%;text-align: left; margin-left: 30px;" class="screen_small pr-0">
              <p style="font-size: 26px;text-align: center;color: red;">សូមធ្វើការទាក់ទងទៅកាន់ក្រុមការងារ bench</p>
            </v-col>
            <v-col sm="12" md="12" cols="12" style="padding: 0;max-width: 90%;text-align: left; margin-left: 30px;" class="screen_small pr-0">
                <p style="    font-size: 16px;
                text-align: center;
                border: 1px solid #ccc;
                padding: 20px;">លេខទូរស័ព្ទ ឬ Telegram: +855 31 41 31 888</p>
            </v-col>
            <v-col sm="12" md="12" cols="12" style="padding: 0;max-width: 90%;text-align: left; margin-left: 30px;" class="screen_small pr-0">
                <p style="    font-size: 16px;
                text-align: center;
                border: 1px solid #ccc;
                padding: 20px;
                background: #eee;">លេខទូរស័ព្ទ ឬ Telegram: +855 96 474 6668</p>
            </v-col>
            <v-col sm="12" md="12" cols="12" style="padding: 0;max-width: 90%;text-align: center; margin-left: 30px;" class="screen_small pr-0">
                <v-btn
                    color="blue-grey"
                    id="ccAgain"
                    class="white--text"
                    link route to="pos"
                >
                    ពិនិត្យឡើងវិញ | Check again
                </v-btn>
                <!-- <v-btn
                    color="blue-grey"
                    id="ccAgain"
                    class="white--text"
                    @click="noirFunc"
                >
                    ពិនិត្យឡើងវិញ | Check again
                </v-btn> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { i18n } from '@/i18n';
const loyaltyHandler = require("@/scripts/loyalty/handler/loyaltyHandler")
import kendo from "@progress/kendo-ui"
const $ = kendo.jQuery
// const $ = kendo.jQuery
const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''
// Import the functions you need from the SDKs you need
const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getDatabase, ref, get,  onChildChanged, child } from "firebase/database";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDrI1fDDjpI5TowIT157Jf---by8xg4zOY",
    authDomain: "banhji-front-display.firebaseapp.com",
    databaseURL: "https://banhji-front-display-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "banhji-front-display",
    storageBucket: "banhji-front-display.appspot.com",
    messagingSenderId: "356489884091",
    appId: "1:356489884091:web:a0af427c8a87f95ac2d49e"
};
initializeApp(firebaseConfig);
let regis = localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')) : {}
let rid = regis.pk || ''
const ordsRef = ref(getDatabase(), `maintenance/${rid}`);
// let isMaintenance = true
const getDB = function(){
    let rid = regis.pk || ''
    if(rid != ''){
        const dbRef = ref(getDatabase());
        get(child(dbRef, 'maintenance/' + rid)).then((snapshot) => {
        if (snapshot.exists()) {
            // if(Object.keys(snapshot.val().data).length > 0){
                window.console.log(snapshot.val().maintenance, 'console on realtime db')
                let m = snapshot.val().maintenance
                if(!m){
                  let ah = window.location.href
                  let link = ah.replace('maintenance', '')
                  window.location.href = link
                }
                // viewModel.setData(snapshot.val().data)
            // }
        } else {
            window.console.log("No data available");
        }
        }).catch((error) => {
            window.console.log(error);
        });
    }
}
onChildChanged(ordsRef, () => {
    getDB()
});
// tra
export default {
  name: "Maintenance",
  data: () => ({
    txnIds: [],
    items: [],
    txnData: [],
    setting: localStorage.getItem(instituteId + 'commRSetting') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRSetting')) : {},
    activeStore: localStorage.getItem(instituteId + 'commStore') != null ? JSON.parse(localStorage.getItem(instituteId + 'commStore')) : {},
    register: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')) : {},
  }),
  mounted(){
    this.checkAppMaintenance()
  },
  components: {
  },
  methods: {
    initRot(){
      window.console.log(this.isMaintenance, 'in' )
      if(!this.isMaintenance){
        this.$router.push({name: 'pos'})
      }
    },
    async checkAppMaintenance(){
      let data = {
          sk: this.register.pk,
          pk: 'checkmaintenance'
      }
      await commerceHandler.generalGet(data).then(res=>{
        // window.console.log(res, 'res from maintenance')
        if(res.data.data.Items.length > 0){
          let isMaintenance = res.data.data.Items[0].isMaintenance || false
          this.isMaintenance = isMaintenance
        }
        // this.$router.push({name: 'maintenance'})
      })
    },
    noirFunc(){
      this.items = []
      this.txnData = []
      this.searchSale({})
    },
    searchSale(key){
      let st = new Date('2024-Mar-01 00:00:00').getTime().toString();
      let ed = new Date('2024-Mar-30 23:59:59').getTime().toString();
      let d = {
        epsAtt: {
          ':dateSk': this.activeStore.pk + '/txns',
          ':startDate': st,
          ':endDate': ed,
        },
        index: 'dateSk-searchDate-index',
        keyCondition: 'dateSk = :dateSk and searchDate between :startDate and :endDate',
        key: key
      }
      commerceHandler.reportGets(d).then(res => {
        let data = res.data.data.Items
        if(data.length > 0){
          data.sort(function (a, b) {
              return parseFloat(new Date(a.issuedDate).getTime()) - parseFloat(new Date(b.issuedDate).getTime())
          })
          data.forEach(d => {
              if(d.type != 'serving' && d.txnNumber != ''){
                if(parseFloat(d.voidAmount) <= 0){
                  if(d.type != 'return'){
                      this.txnIds.push(d.pk)
                      this.txnData.push(d)
                  }
                }
              }
          })
          if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
              let dkey = res.data.data.LastEvaluatedKey
              this.searchSale(dkey)
          }else{
            window.console.log(this.txnData, this.txnIds)
            this.searchItems({})
          }
        }else{
          this.searchItems({})
        }
      })
    },
    searchItems(key){
      let st = new Date('2024-Mar-01 00:00:00').getTime().toString();
      let ed = new Date('2024-Mar-30 23:59:59').getTime().toString();
      let d = {
          epsAtt: {
              ':dateSk': this.activeStore.pk + '/items',
              ':startDate': st,
              ':endDate': ed,
          },
          index: 'dateSk-searchDate-index',
          keyCondition: 'dateSk = :dateSk and searchDate between :startDate and :endDate',
          key: key
      }
      commerceHandler.reportGets(d).then(res => {
        let data = res.data.data.Items
        if(data.length > 0){
          data.sort(function (a, b) {
            return parseFloat(new Date(b.issuedDate).getTime()) - parseFloat(new Date(a.issuedDate).getTime())
          })
          // window.console.log(data, 'data  items')
          if(data.length > 0){
            this.bindItem(data)
            if(data.hasOwnProperty('LastEvaluatedKey')){
              this.searchItems(data.LastEvaluatedKey)
            }else{
              this.itemCom()
            }
          }
        }else{
          this.itemCom()
        }
      })
    }, 
    bindItem(d){
        d.forEach(dd=>{
          this.items.push(dd)
        })
    },
    getHourTime(startDate, endDate) {
        // Calculate the difference in milliseconds
        const difference = Math.abs(startDate - endDate);

        // Convert milliseconds to hours and minutes
        const hours = Math.floor(difference / 1000 / 60 / 60);
        const minutes = Math.floor((difference / 1000 / 60) % 60);

        return hours + '.' + minutes;
    },
    async itemCom(){
      let items = []
      if(this.items.length > 0){
        this.items.forEach(d=>{
          if($.inArray(d.sk, this.txnIds) != -1) {
              let txn = this.txnData.filter((o)=>{return o.pk == d.sk})
              let cutype = d.cusType || ''
              d.exchangeRate = ''
              let campaignname = ''
              let shift = 1
              let cashier = ''
              let emp = ''
              let emps = d.employees || []
              if(emps.length > 0){
                  emps.forEach(ee=>{
                      emp += ',' + ee.employeeId + ' ' + ee.name
                  })
                  emp = emp.substring(1)
              }
              // let sname = this.activeStore.name
              let isvoid = false
              let orderType = ''
              if(txn.length > 0){
                  if(cutype == ''){
                      let customer = txn[0].customer || {}
                      cutype = customer.type || 'General'
                  }
                  shift = txn[0].shift || this.shiftNum
                  let cam = txn[0].campaigns || []
                  if(cam.length > 0){
                      cam.forEach(c=>{
                          campaignname += ',' + c.name
                      })
                  }
                  if(campaignname.length > 0){
                      campaignname = campaignname.substring(1)
                  }
                  let otype = txn[0].orderType || {}
                  orderType = otype.name || ''
                  d.campaign = campaignname
                  let pin = txn[0].pinUser || {}
                  cashier = pin.name || ''
                  d.grandTotal = parseFloat(d.amount)
                  let adis = parseFloat(d.discount)
                  // let asubtotal = atotal - adis
                  let atax = parseFloat(d.tax) || 0
                  if(txn[0].invoiceType == 'commercial'){
                      atax = d.grandTotal / 11
                  }
                  // let agrandtotal = asubtotal + atax
                  d.invoiceType = txn[0].invoiceType || ''
                  d.total = d.grandTotal - atax
                  d.discount = adis
                  d.subTotal = d.grandTotal
                  d.tax = atax
                  d.exchangeRate = txn[0].exchangeRate
                  let dtype = i18n.t('sale')
                  if(txn[0].type == 'return'){
                      dtype = i18n.t('sale_return1')
                      d.total = d.amount
                      d.discount = 0
                      d.subTotal = d.amount
                      d.tax = 0
                      d.grandTotal = parseFloat(d.amount) * -1
                      // d.invoiceType = ''
                  }
                  d.txnType = dtype
                  if(txn[0].voidAmount > 0){
                      isvoid = true
                  }
              }
              let uoms = d.uom || {}
              let uom = uoms.name || ''
              let catname = d.cateName || ''
              let conversionRate = uoms.conversionRate || 1
              if(!isvoid){
                  let itotal = (parseInt(d.qty) * parseFloat(d.price))
                  let idis = parseFloat(d.discount) || 0
                  let icommis = parseFloat(d.saleCommission) || 0
                  let iotherc = parseFloat(d.otherCharge) || 0
                  let ibankc = parseFloat(d.bankCharge) || 0
                  let itax = parseFloat(d.tax) || 0
                  let isubt = itotal - idis - icommis + iotherc - ibankc
                  let igrandt = isubt - itax
                  const timecal = this.getHourTime(new Date(d.orderDate), new Date(d.billDate))
                  items.push([
                      this.register.name || '',
                      kendo.toString(new Date(d.issuedDate), 'yyyy-MMM-dd'),
                      shift,
                      d.orderDate != '' ? kendo.toString(new Date(d.orderDate), 'hh:mm tt') : '',
                      kendo.toString(new Date(d.billDate), 'hh:mm tt'),
                      timecal,
                      d.txnNum,
                      d.saleUnit,
                      cashier,
                      emp,
                      orderType,
                      cutype != '' ? i18n.t(cutype.toLowerCase()) : i18n.t('general'),
                      d.cusName,
                      catname,
                      d.itmNum || d.sku,
                      d.itmName,
                      uom,
                      d.cup || '',
                      conversionRate,
                      d.altName || '',
                      d.paymentMethod.toLowerCase(),
                      txn[0].payNature || 'Cash',
                      d.qty,
                      d.price,
                      itotal,
                      idis,
                      icommis,
                      iotherc,
                      ibankc,
                      isubt,
                      itax,
                      igrandt
                  ])
              }
          }
        })
        window.console.log(items, 'items')
        let csplit = []
        while(items.length) {
          let b = items.splice(0,300)
          csplit.push({
            datas: b
          })
        }
        if(csplit.length > 0){
          // csplit.forEach(s=>{
          //   setTimeout(()=>{
          //     this.saveEntity(s.datas)
          //   }, 500)
          // })
          for(const d of csplit){
            await this.saveSheet(d.datas)
          }
        }
      }
    },
    async saveSheet(data){
      let sheetName = kendo.toString(new Date(), 'yyyy-MMM')
      let sheet = this.setting.sheetBy || ''
      if(sheet != '' && sheet == 'year'){
        sheetName = new Date().getFullYear().toString()
      }
      let d = {
        sheetName: sheetName,
        sheetId: this.setting.sheetId,
        items: data,
        type: 'shiftReport'
      }
      await loyaltyHandler.sheetCreate(d)
    }
  },
  watch: {
    $route: 'checkAppMaintenance'
  },
};
</script>

<style scoped>
.link_app {
  display: block;
  padding: 10px;
}

.v-btn--outlined {
  border: thin solid #d6d6d6 !important;
}

.link_app:hover {
  background: rgb(226, 226, 226);
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 2px 0;
  font-size: 20px;
  line-height: 1.428571429;
  border-radius: 15px;
  font-weight: 700;
  background-color: #000;
  color: #fff;
}

.btn-circle p {
  color: #000;
  font-size: 25px;
  font-weight: 700;
  float: left;
  text-align: left;
  position: absolute;
  top: -3px;
  right: 0;
  margin-top: 0;
}

.btn-primary p {
  color: #007bff;
}

.btn-circle.btn-primary {
  background-color: #007bff !important;
}

.img_center {
  height: 70px;
  display: block;
  margin: auto;
}

.my_row {
  margin-top: 11% !important;
}

@media (max-width: 768px) {
  .screen_small {
    margin-top: 0px;
  }

  .screen_small3 {
    margin-top: 60px;
  }

  .my_row {
    margin-top: 4% !important;
  }
}

.footer {
  color: #333;
  font-size: 16px;
  height: auto;
  float: left;
  width: 80%;
  position: fixed;
  bottom: 0;
  padding: 15px 0;
}

.text-truly {
  width: 80%;
  line-height: 40px;
  font-size: 36px;
}

@media (max-width: 768px) {
  .footer {
    color: #333;
    font-size: 16px;
    height: auto;
    float: left;
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 15px 0;
  }

  .text_pwd {
    line-height: 11px !important;
  }
}

@media (max-width: 992px) {
  .screen_small {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .text-that-also {
    font-size: 18px;
    width: 100% !important;
    line-height: 25px;
  }

  .text_pwd {
    line-height: 11px !important;
  }

  .screen_small2 {
    margin-top: 200px !important;
  }

  .text-truly {
    width: 100%;
  }
}

@media (max-width: 1030px) {
  .text-that-also {
    font-size: 18px;
    width: 100% !important;
    line-height: 25px;
  }

  .text-truly {
    width: 100%;
  }

  .text_pwd {
    line-height: 11px !important;
  }
}

.line_13 {
  line-height: 13px;
}

.line_40 {
  line-height: 40px;
}

.to_access {
  font-size: 40px;
  line-height: 49px;
  font-family: "Niradei-Light", serif !important;
}

.that_also {
  font-size: 21px;
  color: #fff;
  line-height: 26px;
  font-family: 'Niradei-Light';
}
</style>
